<template>
  <div class="">
    <CCard style="z-index: 1;">
      <CCardBody>
        <CForm @submit.prevent="create" method="POST">
          <CRow>
            <CCol>
              <h3>{{ operationName }} Experience - Basic Information</h3>
            </CCol>
            <CCol col="6"></CCol>
            <CCol class="d-flex justify-content-end">
              <CButton color="secondary" class="btn-lg" @click="goBack" style="margin-right: 15px">
                <CIcon name="cil-list" /> Back to the list
              </CButton>

              <CButton color="primary" type="submit">
                <CIcon name="cil-save" /> Save
              </CButton>
            </CCol>
          </CRow>
          <CAlert v-if="message" :color="alertType">
            {{ message }}
          </CAlert>
          <b-tabs content-class="mt-3">
            <b-tab title="General">
              <template>
                <CCard>
                  <CCardBody>
                    <CRow style="margin-top: -30px !important">
                      <CCol col="7" class="d-flex flex-row align-items-center">
                        <CIcon name="cil-pencil" style="margin-right: 10px" />
                        <h4>General Information</h4>
                      </CCol>
                      <CCol class="text-right">
                        <div><label>Active</label></div>
                        <CSwitch color="success" size="lg" :checked.sync="experience.active"></CSwitch>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <label style="width: 95%">Experience Name
                          <a @click="
          toggleNameTranslations = !toggleNameTranslations
          ">
                            <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                          </a>
                        </label>

                        <div class="form-group">
                          <div class="input-group" v-if="experience.nameTranslations[0]"
                            v-show="!toggleNameTranslations">
                            <div class="input-group-prepend">
                              <span v-text="experience.nameTranslations[0].languageCode
          " class="input-group-text" v-show="!toggleNameTranslations" />
                            </div>
                            <input id="name" name="name" type="text" class="form-control"
                              v-model="experience.nameTranslations[0].name" />
                          </div>
                        </div>
                        <div class="form-group" v-show="toggleNameTranslations"
                          v-for="(language, k) in experience.nameTranslations" :key="k">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span v-text="language.languageCode" class="input-group-text" />
                            </div>
                            <input @change="
          saveNameTranslation(
            language.languageCode,
            $event
          )
          " type="text" class="form-control" @id="language.languageCode"
                              v-model="experience.nameTranslations[k].name" />
                          </div>
                        </div>
                      </CCol>

                      <CCol>
                        <CInput label="Experience Code" type="text" v-model="experience.experienceCode"></CInput>
                      </CCol>
                      <CCol>
                        <CInput label="Sort order" type="text" v-model="experience.sortOrder"></CInput>
                      </CCol>
                      <CCol>
                        <label style="width: 95%">URL-address
                          <a @click="
          toggleUrlTranslations = !toggleUrlTranslations
          ">
                            <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                          </a>
                        </label>
                        <div class="form-group">
                          <div class="input-group" v-if="experience.experienceUrlTranslations[0]"
                            v-show="!toggleUrlTranslations">
                            <div class="input-group-prepend">
                              <span v-text="experience.experienceUrlTranslations[0]
          .languageCode
          " class="input-group-text" />
                            </div>
                            <input id="name" name="name" type="text" class="form-control" v-model="experience.experienceUrlTranslations[0].name
          " />
                          </div>
                        </div>
                        <div class="form-group" v-show="toggleUrlTranslations" v-for="(
                            language, k
                          ) in experience.experienceUrlTranslations" :key="k">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span v-text="language.languageCode" class="input-group-text" />
                            </div>
                            <input @change="
          saveUrlTranslation(
            language.languageCode,
            $event
          )
          " type="text" class="form-control" @id="language.languageCode" v-model="experience.experienceUrlTranslations[k].name
          " />
                          </div>
                        </div>
                      </CCol>
                      <CCol>
                        <CSelect label="Currency" :value.sync="experience.currencyId" :plain="true"
                          :options="currencies">
                        </CSelect>
                      </CCol>
                      <CCol col="12">
                        <label style="width: 95%">Experience Description
                          <a @click="
          toogleExperienceDescription =
          !toogleExperienceDescription
          ">
                            <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                          </a>
                        </label>
                        <div class="form-group">
                          <div class="input-group" v-if="experience.experienceDescription[0]"
                            v-show="!toogleExperienceDescription">
                            <div class="input-group-prepend">
                              <span v-text="experience.experienceDescription[0]
          .languageCode
          " class="input-group-text" v-show="!toogleExperienceDescription" />
                            </div>
                            <textarea id="name" name="name" type="text" class="form-control"
                              style="min-height: 150px !important" v-model="experience.experienceDescription[0].name" />
                          </div>
                        </div>
                        <div class="form-group" v-show="toogleExperienceDescription" v-for="(
                            language, k
                          ) in experience.experienceDescription" :key="k">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span v-text="language.languageCode" class="input-group-text" />
                            </div>
                            <textarea @change="
          saveExperienceDescriptionTranslation(
            language.languageCode,
            $event
          )
          " type="text" class="form-control" @id="language.languageCode" style="min-height: 150px !important"
                              v-model="experience.experienceDescription[k].name" />
                          </div>
                        </div>
                      </CCol>
                      <CCol sm="3">
                        <CSelect label="Guest Group" :value.sync="experience.guestGroupId" :plain="true"
                          :options="guestGroups">
                        </CSelect>
                      </CCol>

                      <CCol sm="3">
                        <label style="width: 95%">Category </label>

                        <div class="form-group">
                          <div class="input-group">
                            <treeselect v-model="experience.categoriesIds" :defaultExpandLevel="Infinity"
                              :isDefaultExpanded="true" :flat="true" :multiple="true" :options="categories" />
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol col="12" class="d-flex flex-row align-items-center">
                        <CIcon name="cil-clock" style="margin-right: 10px" />
                        <h4>Sales Period</h4>
                      </CCol>
                    </CRow>
                    <CRow style="padding-top: 10px">
                      <CCol col="3">
                        <label>From</label>
                        <ejs-datetimepicker v-model="experience.saleFrom" :format="$dateFormatInput"
                          :timeFormat="$dateFormatInput.split(' ').length > 1 ? `${$dateFormatInput.split(' ')[1]} ${$dateFormatInput.split(' ')[2]}` : $dateFormatInput.split(' ')[1]"></ejs-datetimepicker>
                      </CCol>
                      <CCol col="3">
                        <label>To</label>
                        <ejs-datetimepicker v-model="experience.saleTo" :format="$dateFormatInput"
                          :timeFormat="$dateFormatInput.split(' ').length > 1 ? `${$dateFormatInput.split(' ')[1]} ${$dateFormatInput.split(' ')[2]}` : $dateFormatInput.split(' ')[1]"></ejs-datetimepicker>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="12" class="d-flex flex-row align-items-center">
                        <CIcon name="cil-camera" style="margin-right: 10px" />
                        <h4>Thumbnails and images</h4>
                      </CCol>
                    </CRow>
                    <images-uploader :thumbnails="thumbnails"
                      @update:thumbnails="(thumbnailsFromUL) => updateThumbnails(thumbnailsFromUL)"></images-uploader>
                  </CCardBody>
                </CCard>
              </template>
            </b-tab>
            <b-tab title="Advanced Options">
              <CCard>
                <CCardBody>
                  <CRow style="padding-top: 15px">
                    <CCol col="12" class="d-flex flex-row align-items-center">
                      <CIcon name="cil-keyboard" style="margin-right: 10px" />
                      <h4>Developer Extensions</h4>
                    </CCol>
                  </CRow>
                  <CRow style="padding-top: 10px">
                    <CCol col="12">
                      <label style="width: 95%">Add-to-basket dialogue extension
                        <a @click="
          toggleDeveloperExtensionTranslations =
          !toggleDeveloperExtensionTranslations
          ">
                          <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
                        </a>
                      </label>
                      <div class="form-group">
                        <div class="input-group" v-if="experience.developerExtensionTranslations[0]"
                          v-show="!toggleDeveloperExtensionTranslations">
                          <div class="input-group-prepend">
                            <span v-text="experience.developerExtensionTranslations[0]
          .languageCode
          " class="input-group-text" v-show="!toggleDeveloperExtensionTranslations" />
                          </div>
                          <textarea id="name" name="name" type="text" class="form-control"
                            style="min-height: 150px !important" v-model="experience.developerExtensionTranslations[0].name
          " />
                        </div>
                      </div>
                      <div class="form-group" v-show="toggleDeveloperExtensionTranslations" v-for="(
                          language, k
                        ) in experience.developerExtensionTranslations" :key="k">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span v-text="language.languageCode" class="input-group-text" />
                          </div>
                          <textarea @change="
          saveDeveloperExtensionTranslation(
            language.languageCode,
            $event
          )
          " type="text" class="form-control" @id="language.languageCode" style="min-height: 150px !important" v-model="experience.developerExtensionTranslations[k].name
          " />
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CSelect label="Cancellation policy" :value.sync="experience.cancellationPolicyId" :plain="true"
                        :options="cancellationPolicies">
                      </CSelect>
                    </CCol>
                    <CCol v-if="paymentMethods.length > 0 && isDataLoaded">
                      <label>Payment methods</label>
                      <CMultiSelect :multiple="true" :selection="true" v-model="experience.partnerPaymentMethods"
                        optionsEmptyPlaceholder="No payment methods available" selectionType="text"
                        @update="appendValue($event)" :options="paymentMethods"
                        :selected="experience.partnerPaymentMethods" />
                    </CCol>
                  </CRow>

                </CCardBody>
              </CCard>
            </b-tab>
            <b-tab title="Related Experiences">
              <CCard>
                <CCardBody>
                  <CRow style="margin-top: -30px !important">
                    <CCol col="7" class="d-flex flex-row align-items-center">
                      <CIcon name="cil-pencil" style="margin-right: 10px" />
                      <h4>Related Experiences</h4>
                    </CCol>
                  </CRow>
                  <CRow style="padding-top: 10px">
                    <CCol sm="3">
                      <CSelect label="Related Experiences" :plain="true" :value.sync="addedExperience"
                        :options="relatedExperiences">
                      </CSelect>
                    </CCol>
                    <CCol col="3">
                      <CButton color="success" class="btn" @click="pushRelateExperience()"
                        style="margin-top: 3.5vh !important">
                        <CIcon name="cilSmilePlus"></CIcon>
                        Add Experience
                      </CButton>
                    </CCol>
                  </CRow>
                  <li v-for="item in experience.relatedExperiences">
                    {{
          relatedExperiences.find((x) => x.value == item.id)?.label
        }}
                    <CButton style="margin-left: 10px" @click="eliminateRelatedExperience(item.id)">
                      <CIcon name="cilXCircle" />
                    </CButton>
                  </li>
                </CCardBody>
              </CCard>
            </b-tab>
            <b-tab title="Closures" :active="isClosuresTabActive">
              <CCard>
                <CCardBody v-if="experienceId">
                  <CRow>
                    <CCol col="9" class="d-flex flex-row align-items-center">
                      <CIcon name="cil-ban" style="margin-right: 10px" />
                      <h4>Closures</h4>
                    </CCol>
                    <CCol col="3" class="text-right">
                      <CButton color="success" class="btn" @click="createClosure()">
                        <CIcon name="cil-libraryAdd"></CIcon>
                        Add new closure
                      </CButton>
                    </CCol>
                  </CRow>
                  <CAlert v-if="closureMessage" :color="closureAlertType">
                    {{ closureMessage }}
                  </CAlert>
                  <br />
                  <CDataTable hover :items="closuresItems" :fields="closuresFields" :items-per-page="5" pagination>

                    <template #startTime="{ item }">
                      <td>
                        {{ moment(new Date(item.startTime)).format($dateFormat) }}
                      </td>
                    </template>

                    <template #endTime="{ item }">
                      <td>
                        {{ moment(new Date(item.endTime)).format($dateFormat) }}
                      </td>
                    </template>

                    <template #Edit="{ item }">
                      <td>
                        <CButton color="primary" @click="editClosure(item.id)">
                          <CIcon name="cil-pencil" /> Edit
                        </CButton>
                      </td>
                    </template>

                    <template #Delete="{ item }">
                      <td>
                        <CButton color="danger" @click="deleteClosure(item.id)">Delete</CButton>
                      </td>
                    </template>
                  </CDataTable>
                </CCardBody>
              </CCard>
            </b-tab>
            <b-tab @click="launchPreview()">

              <template slot="title">
                <CIcon name="cilExternalLink" />
                Preview
              </template>
            </b-tab>
          </b-tabs>
        </CForm>
      </CCardBody>
    </CCard>
    <CCard v-if="experience.id != emptyGuid">
      <CCardBody>
        <CRow>
          <CCol col="9" class="d-flex flex-row align-items-center">
            <CIcon name="cil-calendar" style="margin-right: 10px" />
            <h4>Schedules</h4>
          </CCol>
          <CCol col="3" class="text-right">
            <CButton color="success" class="btn" @click="addExperienceBreakdown(experience.id)">
              <CIcon name="cil-libraryAdd"></CIcon>
              Add new schedule
            </CButton>
          </CCol>
        </CRow>
        <br />
        <CDataTable :items="items" :fields="fields" hover>

          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.active)">
                {{ item.active == true ? "Active" : "Inactive" }}
              </CBadge>
            </td>
          </template>

          <template #startTime="{ item }">
            <td>
              {{ moment(new Date(item.startTime)).format($dateFormat).split(" ")[0] }}
            </td>
          </template>

          <template #endTime="{ item }">
            <td>
              {{ moment(new Date(item.endTime)).format($dateFormat).split(" ")[0] }}
            </td>
          </template>

          <template #active="{ item }">
            <td>
              <CBadge :color="getBadge(item.active)">
                {{ item.active == true ? "Active" : "Inactive" }}
              </CBadge>
            </td>
          </template>

          <template #action="{ item }">
            <td>
              <CButton color="primary" style="margin-right: 10px !important" @click="editExperienceSchedule(item.id)">
                <CIcon name="cil-pencil" /> Edit
              </CButton>
              <CButton style="margin-left: 10px" color="success" @click="cloneSchedule(item.id)">
                <CIcon name="cil-libraryAdd" /> Clone
              </CButton>
              <CButton color="danger" style="margin-left: 10px" @click="
          warningModal = true;
        schedule = item;
        ">
                <CIcon name="cil-trash" /> Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CModal v-model="schedule.id" title="Are you sure to delete selected experience schedule?" color="danger"
          :show.sync="warningModal" @update:show="closeModal">
          <CRow>
            <CCol col="">
              <CInput label="Selected experience schedule name:" v-model="schedule.name" disabled />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ImagesUploader from "@/components/ImagesUploader";
import { DateTimePickerComponent } from '@syncfusion/ej2-vue-calendars';

export default {
  name: "EditExperience",
  components: {
    Treeselect,
    ImagesUploader,
    'ejs-datetimepicker': DateTimePickerComponent,
  },
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),
      experienceId: null,

      items: [],
      thumbnails: [],
      fields: [
        "startTime",
        "endTime",
        "duration",
        "name",
        "priority",
        "active",
        "action",
      ],
      paymentMethods: [],
      isDataLoaded: false,
      toggleNameTranslations: false,
      toggleDeveloperExtensionTranslations: false,
      toogleExperienceDescription: false,
      toogleGroupNameTranslations: false,
      toggleUrlTranslations: false,

      //closures
      closuresFields: ["name", "startTime", "endTime", "Edit", "Delete"],
      closuresItems: [],
      closureMessage: null,
      closureAlertType: "danger",
      isClosuresTabActive: false,

      //dropdowns
      taxClasses: [],
      guestGroups: [],
      currencies: [],
      languages: [],
      relatedExperiences: [],
      cancellationPolicies: [],
      categories: [],

      experience: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        active: true,
        experienceUrlTranslations: [],
        experienceUrl: null,
        currencyId: null,
        guestGroupId: null,
        taxClassId: "00000000-0000-0000-0000-000000000000",
        nameTranslations: [],
        developerExtensionTranslations: [],
        cancellationPolicyId: "00000000-0000-0000-0000-000000000000",
        experienceDescription: [],
        relatedExperiences: [],
        experienceCode: null,
        thumbnailUrl: null,
        saleFrom: null,
        saleTo: null,
        groupNameTranslations: [],
      },

      addedExperience: null,
      //Delete
      schedule: {
        id: null,
        name: null,
      },

      warningModal: false,
      //Alert
      message: null,
      alertType: "danger",
    };
  },
  methods: {
    cloneSchedule(id) {
      if (this.experience.id == this.emptyGuid) {
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        self.message = "Experience should be saved before creating a schedule";
      }
      let self = this;
      axios
        .get(
          `${this.$apiAdress}/v1/Experience/${self.experience.id}/experienceSchedule/clone/${id}`
        )
        .then(function () {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully cloned schedule.";
          self.getExperienceSchedule();
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    eliminateRelatedExperience(id) {
      this.experience.relatedExperiences =
        this.experience.relatedExperiences.filter((x) => x.id != id);
    },
    getPaymentMethods() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerPaymentMethods`)
        .then(function (response) {
          self.items = response.data;
          self.paymentMethods.push(
            ...response.data.map((paymentMethod) => {
              return {
                value: paymentMethod.id,
                text:
                  paymentMethod.paymentMethodsType == 1
                    ? "Stripe Card"
                    : paymentMethod.paymentMethodsType == 2
                      ? "Stripe Klarna"
                      : "Pay Later  - " + paymentMethod.nameTranslations[0].name,
              };
            })
          );
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    pushRelateExperience() {
      const self = this;  // Use self to ensure the correct context in case of inner functions/callbacks.

      if (self.addedExperience != null && self.addedExperience != "") {
        // Check if the experience already exists in the relatedExperiences array
        const experienceExists = self.experience.relatedExperiences.some(exp => exp.id === self.addedExperience);

        if (experienceExists) {
          // If the experience exists, set the error message
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = "The experience already exists.";
        } else {
          // If the experience doesn't exist, add it to the array
          self.experience.relatedExperiences.push({ id: self.addedExperience });
          self.addedExperience = null;
        }
        self.addedExperience = null;
      }
    },
    appendValue(event) {
      this.experience.partnerPaymentMethods = event;
    },
    deleteClosure(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/Closures/${id}`)
        .then(function () {
          self.closureAlertType = "success";
          self.closureMessage = "Successfully deleted closure.";
          setTimeout(function () {
            self.closureMessage = null;
          }, 5000);
          self.getClosures();
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    createClosure() {
      this.$router.push({
        path: `/experiences/${this.experience.id}/closure/${this.emptyGuid}`,
      });
    },
    editClosure(id) {
      this.$router.push({
        path: `/experiences/${this.experience.id}/closure/${id}`,
      });
    },
    launchPreview() {
      window.swWidgetApp.$router.push({
        name: 'BookingModalView',
        query: {
          expcode: this.experience.experienceCode,
          expname: encodeURIComponent(this.experience.nameTranslations[0].name),
          widgetsrc: this.$widgetSrc,
          partnerid: this.partnerId
        }
      });
    },
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.schedule.id);
      }
    },
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },

    editExperienceSchedule(id) {
      this.$router.push({
        path: `/experiences/${this.experience.id}/schedule/${id}`,
      });
    },
    schedulingLink(id) {
      return `/experiences/${id.toString()}/schedule/00000000-0000-0000-0000-000000000000`;
    },
    addExperienceBreakdown(id) {
      const schedulingLink = this.schedulingLink(id);
      this.$router.push({ path: schedulingLink });
    },
    goBack() {
      if (this.$route.query.closure)
        this.$router.push({
          path: `/experiences`,
        });
      else
        this.$router.go(-1);
    },

    getCurrencies() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/PartnerCurrency/CurrenciesList")
          .then(function (response) {
            self.currencies = response.data;
            resolve();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            reject();
          });
      });
    },

    getCancellationPolicies() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/CancellationPolicy/CancellationPolicies")
          .then(function (response) {
            self.cancellationPolicies = response.data;
            resolve();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            reject();
          });
      });
    },
    getLanguages() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/PartnerLanguage`)
          .then(function (response) {
            self.languages = response.data;
            if (self.experience.id == self.emptyGuid) {
              for (let i = 0; i < self.languages.length; i++) {
                self.experience.experienceUrlTranslations.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });
                self.experience.nameTranslations.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });
                self.experience.developerExtensionTranslations.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });

                self.experience.experienceDescription.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });
                self.experience.groupNameTranslations.push({
                  languageCode: self.languages[i].languageCode,
                  name: null,
                  languageId: self.languages[i].languageId,
                });
              }
            } else {
            }
            resolve();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            reject();
          });
      });
    },
    getGuestGroups() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/GuestGroup/GuestGroupsList")
          .then(function (response) {
            self.guestGroups = response.data;
            resolve();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            reject();
          });
      });
    },
    getClosures() {
      let self = this;
      axios
        .get(
          this.$apiAdress + "/v1/Closures/GetByExperience/" + self.experienceId
        )
        .then(function (response) {
          self.closuresItems = response.data;
        })
        .catch(function (error) {
          self.closureAlertType = "danger";
          self.closureMessage = error;
          setTimeout(function () {
            self.closureMessage = null;
          }, 10000);
        });
    },
    formatDateStr(dateStr) {
      return new Date(dateStr).toLocaleDateString();
    },
    formatDuration(durationObj) {
      let str = "";
      let days = durationObj.days;
      let hours = durationObj.hours;
      let minutes = durationObj.minutes;
      if (days && days) str = days + (days === 1 ? " day" : " days");
      if (hours && hours > 0) {
        if (str.length > 0) str += ", ";
        str += hours + (hours === 1 ? " hour" : " hours");
      }
      if (minutes && minutes > 0) {
        if (str.length > 0) str += ", ";
        str += minutes + (minutes === 1 ? " minute" : " minutes");
      }
      return str;
    },
    getExperienceSchedule() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ExperienceSchedule/" + self.experience.id)
        .then(function (response) {
          self.items = response.data;
          for (let item of self.items) {
            //item.startTime = self.formatDateStr(item.startTime);
            //item.endTime = self.formatDateStr(item.endTime);
            if (item.duration)
              item.duration = self.formatDuration(item.duration);
          }
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getTaxClasses() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/ListItem/TaxClasses")
          .then(function (response) {
            self.taxClasses = response.data;
            resolve();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            reject();
          });
      });
    },
    getRelatedExperiences(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/GetRelatedExperiences/" + id)
        .then((response) => {
          if (response && response.data) {
            self.relatedExperiences = response.data;
          }
        })
        .catch((error) => {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    formatToISO8601String(date) {
      if (!date) return ""; // If no date is provided, return an empty string
      const dateObj = new Date(date); // Create a Date object from the input
      return dateObj.toISOString(); // Convert the Date object to an ISO 8601 string
    },
    toISOWithFixedOffset(date) {
      var pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

      // Format the date to ISO 8601 without timezone information
      var isoDate = date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds());

      // Append a fixed timezone offset of +00:00
      var fixedOffset = '+00:00';

      return isoDate + fixedOffset;
    },
    create() {
      let self = this;
      if (
        self.experience.experienceCode == null ||
        self.experience.experienceCode == undefined ||
        self.experience.experienceCode == ""
      ) {
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        self.message = "Experience Code is mandatory";
        return;
      }
      if (
        self.experience.nameTranslations == null ||
        self.experience.nameTranslations == undefined ||
        self.experience.nameTranslations.length == 0
      ) {
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        self.message = "Name Translation is mandatory";
        return;
      }
      let valid = true;
      self.experience.nameTranslations.forEach((value, index) => {
        if (value.name == null || value.name == undefined || value.name == "") {
          valid = false;
          return;
        }
      });

      if (valid == false) {
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        self.message = "Please complete all Name Translation";
        return;
      }
      const hasNonEmptyName = self.experience.nameTranslations.some(
        (obj) => obj.name !== null && obj.name !== ""
      );

      if (hasNonEmptyName) {
      } else {
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        self.message = "Name Translation is mandatory";
        return;
      }
      let form = new FormData();
      form.append("id", self.experience.id);
      form.append("currencyId", self.experience.currencyId);
      form.append("experienceCode", self.experience.experienceCode);
      form.append(
        "taxClassId",
        self.experience.taxClassId ?? "00000000-0000-0000-0000-000000000000"
      );
      form.append("guestGroupId", self.experience.guestGroupId);
      if (self.experience.saleFrom) {
        let saleFrom = new Date(self.experience.saleFrom);
        let saleFromISO = self.toISOWithFixedOffset(saleFrom);
        form.append("saleFrom", saleFromISO);
      }

      if (self.experience.saleTo) {
        let saleTo = new Date(self.experience.saleTo);
        let saleToISO = self.toISOWithFixedOffset(saleTo);
        form.append("saleTo", saleToISO);

      }

      console.log("format is");
      console.log(self.experience.saleFrom);

      if (self.experience.partnerPaymentMethods) {
        self.experience.partnerPaymentMethods.forEach((guid, index) => {
          form.append(`partnerPaymentMethods[${index}]`, guid);
        });
      }
      form.append(
        "cancellationPolicyId",
        self.experience.cancellationPolicyId == null
          ? ""
          : self.experience.cancellationPolicyId
      );
      form.append("file", self.experience.file);
      let newImages = self.thumbnails?.filter((thumbnail) => thumbnail.file);
      let thumbnailsUrls = self.getThumbnailsUrls();
      if (newImages) {
        let newImagesOrders = [];
        newImages.forEach((newImage) => {
          form.append("files", newImage.file);
          newImagesOrders.push({
            fileName: newImage.file.name,
            orderNumber: newImage.order,
          });
        });
        form.append("newImagesOrdersJson", JSON.stringify(newImagesOrders));
      }

      if (thumbnailsUrls) form.append("thumbnailUrl", thumbnailsUrls);
      form.append("groupName", self.experience.groupName);
      form.append(
        "relatedExperiencesJson",
        JSON.stringify(self.experience.relatedExperiences)
      );
      form.append("active", self.experience.active);
      if (self.experience.categoryId)
        form.append("categoryId", self.experience.categoryId);
      if (self.experience.categoriesIds)
        form.append("categoriesIds", self.experience.categoriesIds);
      form.append(
        "groupNameTranslationsJson",
        JSON.stringify(self.experience.groupNameTranslations)
      );
      form.append(
        "NameTranslationsJson",
        JSON.stringify(self.experience.nameTranslations)
      );
      form.append(
        "experienceUrlTranslationsJson",
        JSON.stringify(self.experience.experienceUrlTranslations)
      );
      form.append(
        "developerExtensionTranslationsJson",
        JSON.stringify(self.experience.developerExtensionTranslations)
      );
      form.append(
        "experienceDescriptionJson",
        JSON.stringify(self.experience.experienceDescription)
      );
      if (self.experience.sortOrder) {
        form.append("sortOrder", self.experience.sortOrder);
      }
      axios
        .post(this.$apiAdress + "/v1/Experience", form)
        .then((response) => {
          self.experience.id = response.data;
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully updated experience.";
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.errors;
        });
    },
    delete(id) {
      let self = this;
      axios
        .delete(
          `${this.$apiAdress}/v1/experienceSchedule/${self.experience.id}/${id}`
        )

        .then(function () {
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully deleted experience schedule.";
          self.getExperienceSchedule();
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    saveNameTranslation: function (value, event) {
      let self = this;
      self.experience.nameTranslations[value] = event.target.value;
    },
    saveDeveloperExtensionTranslation: function (value, event) {
      let self = this;
      self.experience.developerExtensionTranslations[value] =
        event.target.value;
    },
    saveExperienceDescriptionTranslation: function (value, event) {
      let self = this;
      self.experience.experienceDescription[value] = event.target.value;
    },
    saveGroupNameranslation: function (value, event) {
      let self = this;
      self.experience.groupNameTranslations[value] = event.target.value;
    },
    saveUrlTranslation: function (value, event) {
      let self = this;
      self.experience.experienceUrlTranslations[value] = event.target.value;
    },
    formatAPIDate: function (dateStr) {
      if (dateStr != null) {
        let ret = null;
        let year = dateStr.substr(0, 4);
        if (year) {
          year = parseInt(dateStr);
          if (year > 2000) {
            // is it an actual date or db initial 0001
            ret = new Date(dateStr).toISOString().replace("Z", "");
          }
        }
        return ret;
      }
    },
    async get(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Experience/" + id)
        .then(async function (response) {
          if (!response?.data) {
            self.$router.push({ path: "/experiences" }); // if not getting data for this experience id (happens e.g. when switching partner from edit exp view), then go to experiences list view
          } else {
            self.experience = response.data;
            if (self.experience.categoriesIds)
              self.experience.categoriesIds =
                self.experience.categoriesIds.split(",");
            let thumbnailOrderIndex = 0;
            self.thumbnails = self.experience?.thumbnailUrl
              ?.split(",")
              .map((thumbnail) => {
                return {
                  url: thumbnail,
                  order: thumbnailOrderIndex++,
                  blob: null,
                  file: null,
                  show: false,
                };
              });
            self.experience.saleFrom = self.formatAPIDate(
              self.experience.saleFrom
            );
            self.experience.saleTo = self.formatAPIDate(self.experience.saleTo);
            self.getExperienceSchedule();
          }

          let test = self.experience.nameTranslations;
          self.experience.nameTranslations = [];
          test.map(function (value, key) {
            var exists = self.languages.find(
              (x) => x.languageCode == value.languageCode
            );
            if (exists != undefined) {
              self.experience.nameTranslations.push(value);
            }
          });

          let testUrlTranslation = self.experience.experienceUrlTranslations;
          self.experience.experienceUrlTranslations = [];
          testUrlTranslation.map(function (value, key) {
            var exists = self.languages.find(
              (x) => x.languageCode == value.languageCode
            );
            if (exists != undefined) {
              self.experience.experienceUrlTranslations.push(value);
            }
          });

          let categoryTra = self.experience.groupNameTranslations;
          self.experience.groupNameTranslations = [];
          categoryTra.map(function (value, key) {
            var exists = self.languages.find(
              (x) => x.languageCode == value.languageCode
            );
            if (exists != undefined) {
              self.experience.groupNameTranslations.push(value);
            }
          });
          var expDescr = self.experience.experienceDescription;
          self.experience.experienceDescription = [];
          expDescr.map(function (value, key) {
            var exists = self.languages.find(
              (x) => x.languageCode == value.languageCode
            );
            if (exists != undefined) {
              self.experience.experienceDescription.push(value);
            }
          });
          let dialogBasketTest = self.experience.developerExtensionTranslations;
          self.experience.developerExtensionTranslations = [];
          dialogBasketTest.map(function (value, key) {
            var exists = self.languages.find(
              (x) => x.languageCode == value.languageCode
            );
            if (exists != undefined) {
              self.experience.developerExtensionTranslations.push(value);
            }
          });

          for (let i = 0; i < self.languages.length; i++) {
            if (
              !self.experience.nameTranslations.find(
                (t) => t.languageCode === self.languages[i].languageCode
              )
            ) {
              self.experience.nameTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }
            if (
              !self.experience.experienceUrlTranslations.find(
                (t) => t.languageCode === self.languages[i].languageCode
              )
            ) {
              self.experience.experienceUrlTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }

            if (
              !self.experience.groupNameTranslations.find(
                (t) => t.languageCode === self.languages[i].languageCode
              )
            ) {
              self.experience.groupNameTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }

            if (
              !self.experience.developerExtensionTranslations.find(
                (t) => t.languageCode === self.languages[i].languageCode
              )
            ) {
              self.experience.developerExtensionTranslations.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }
            if (
              !self.experience.experienceDescription.find(
                (t) => t.languageCode === self.languages[i].languageCode
              )
            ) {
              self.experience.experienceDescription.push({
                languageCode: self.languages[i].languageCode,
                name: null,
                languageId: self.languages[i].languageId,
              });
            }
          }
          self.isDataLoaded = true;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
          self.isDataLoaded = true;
        });
    },
    loadCategoryHierarchy(categories, pId) {
      return categories
        .filter((c) => c.parentId === pId)
        .map((c) => {
          var subCategories = this.loadCategoryHierarchy(c.subCategories, c.id);
          return {
            id: c.id,
            label: c.names[0].name,
            children: subCategories.length > 0 ? subCategories : undefined,
          };
        });
    },
    getCategories() {
      return new Promise((resolve, reject) => {
        let self = this;
        axios
          .get(`${this.$apiAdress}/v1/category/CategoryHierarchy`)
          .then(function (response) {
            self.categories = [
              {
                id: "00000000-0000-0000-0000-000000000000",
                label: "Main Category",
                children: self.loadCategoryHierarchy(response.data, null),
              },
            ];
            resolve();
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            reject();
          });
      });
    },
    getThumbnailsUrls() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.url)
          .map((thumbnail) => {
            return thumbnail.url;
          })
          .join();
      } else return null;
    },
    getThumbnailsFiles() {
      if (this.thumbnails && this.thumbnails.length > 0) {
        return this.thumbnails
          .filter((thumbnail) => thumbnail.file)
          .map((thumbnail) => {
            return thumbnail.file;
          });
      } else return null;
    },
    updateThumbnails(thumbnails) {
      this.thumbnails = thumbnails;
    }
  },
  async mounted() {
    let self = this;
    if (self.$route.query.closure) {
      self.isClosuresTabActive = true;
    }
    await Promise.all([
      self.getLanguages(),
      self.getCurrencies(),
      self.getTaxClasses(),
      self.getGuestGroups(),
      self.getCancellationPolicies(),
      self.getCategories(),
      self.getPaymentMethods()
    ]);

    if (this.$route.params.id != this.emptyGuid) {
      self.experienceId = this.$route.params.id;
      self.getClosures();
      self.operationName = "Edit";
      await self.get(this.$route.params.id);
      await self.getRelatedExperiences(this.$route.params.id);
    } else {
      self.getRelatedExperiences(this.emptyGuid);
      self.experience.categoriesIds = ["00000000-0000-0000-0000-000000000000"];
      self.isDataLoaded = true;
    }
  },
};
</script>

<style scoped>
.card-body>>>table>tbody>tr>td {
  cursor: pointer;
}
</style>

<style>
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white;
  background-color: blue;
  border-color: #c4c9d0 #c4c9d0 #ebedef;
  font-size: large;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #ced2d8;
  color: black;
}
</style>
